import React from 'react';
import logoLight from 'assets/images/logo.svg';
import logoDark from 'assets/images/logo-white.svg';
import { useSelector } from 'react-redux';
import { useTheme } from '@mui/material/styles';
import { Box, Typography } from '@mui/material';

const Logo = ({ size = 'medium' }) => {
    const siteInfo = useSelector((state) => state.siteInfo);
    const theme = useTheme();
    const logo = theme.palette.mode === 'light' ? logoLight : logoDark;

    const sizes = {
        small: { imgWidth: 30, fontSize: '1rem' },
        medium: { imgWidth: 40, fontSize: '1.25rem' },
        large: { imgWidth: 50, fontSize: '1.5rem' }
    };

    const { imgWidth, fontSize } = sizes[size];

    return (
        <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            width="100%"
        >
            <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                sx={{
                    position: 'relative',
                    width: 'fit-content'
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: imgWidth,
                        height: imgWidth,
                        marginRight: '8px'
                    }}
                >
                    <img
                        src={siteInfo.logo || logo}
                        alt={siteInfo.system_name}
                        style={{
                            maxWidth: '100%',
                            maxHeight: '100%',
                            objectFit: 'contain'
                        }}
                    />
                </Box>
                <Typography
                    variant="h6"
                    component="span"
                    sx={{
                        fontWeight: 'bold',
                        color: theme.palette.text.primary,
                        fontSize: fontSize,
                        whiteSpace: 'nowrap'
                    }}
                >
                    {siteInfo.system_name || '云雾AI'}
                </Typography>
            </Box>
        </Box>
    );
};

export default Logo;
